<template>
  <div class="no-select me">
    <div class="form-group form-group-sm">
      <div
        class="clicable"
        @click.stop.prevent="marker.enabled = !marker.enabled"
      >
        <input type="checkbox" v-model="marker.enabled" />
        {{ $t("show_marker") }}
      </div>
    </div>
    <!-- ICON BEGIN -->
    <div class="form-group form-group-sm">
      <div class="simple-label">{{ $tc("icon") }}</div>
      <div class="input-group">
        <div
          class="input-group-addon btn addon-btn"
          @click.stop.prevent="marker.icon.show = !marker.icon.show"
        >
          <i class="fa fa-eye" v-if="marker.icon.show"></i>
          <i class="fa fa-eye-slash" v-else></i>
        </div>
        <div class="input-group-addon btn addon-btn">
          <ColorPicker
            class="color-picker"
            :pickerStyle="{ left: '-80px' }"
            v-model="marker.icon.color"
          />
        </div>
        <div
          class="input-group-addon btn addon-btn"
          @click.stop.prevent="showIconLibrary = true"
        >
          <i :class="marker.icon.class"></i>
        </div>
        <div
          class="input-group-addon btn addon-btn"
          :title="$t('rotate')"
          @click.stop.prevent="rotateIcon()"
        >
          <i class="fa fa-undo"></i>
        </div>
        <div class="labeled-input-container">
          <input
            class="form-control text-right"
            type="number"
            v-model="iconOffsetY"
            style="width:50%;"
          />
          <input
            class="form-control text-right"
            type="number"
            v-model="iconOffsetX"
            style="width:50%;"
          />

          <div
            class="small-label-input"
            style="left:30%;top:-5px;padding-left:2px;background-color:white;"
          >
            {{ $t("offset") }}
          </div>
        </div>
      </div>
    </div>
    <!-- ICON END -->

    <!-- VALUE BEGIN -->
    <div class="form-group form-group-sm">
      <div class="simple-label">{{ $t("text") }}</div>
      <div class="input-group">
        <div
          class="input-group-addon btn addon-btn"
          @click.stop.prevent="marker.value.show = !marker.value.show"
        >
          <i class="fa fa-eye" v-if="marker.value.show"></i>
          <i class="fa fa-eye-slash" v-else></i>
        </div>
        <div class="input-group-addon btn addon-btn">
          <ColorPicker
            class="color-picker"
            :pickerStyle="{ left: '-80px' }"
            v-model="marker.value.color"
          />
        </div>
        <div class="labeled-input-container">
          <input
            class="form-control text-center"
            v-model="marker.value.format"
            style="width:47%;"
          />
          <input
            class="form-control text-right"
            type="number"
            v-model="valueOffsetY"
            style="width:26%;"
          />
          <input
            class="form-control text-right"
            type="number"
            v-model="valueOffsetX"
            style="width:27%;"
          />
          <div class="small-label-input">
            {{ $t("format") }}
          </div>
          <div
            class="small-label-input"
            style="left:60%;top:-5px;padding-left:2px;background-color:white;"
          >
            {{ $t("offset") }}
          </div>
        </div>
      </div>
    </div>
    <!-- VALUE END -->

    <!-- DASH BEGIN -->
    <div class="form-group form-group-sm">
      <div class="simple-label">{{ $t("dash") }}</div>
      <div class="input-group">
        <div
          class="input-group-addon btn addon-btn"
          @click.stop.prevent="marker.dash.show = !marker.dash.show"
        >
          <!-- <input type="checkbox" v-model="marker.dash.show" /> -->
          <i class="fa fa-eye" v-if="marker.dash.show"></i>
          <i class="fa fa-eye-slash" v-else></i>
        </div>

        <div class="input-group-addon btn addon-btn">
          <ColorPicker
            class="color-picker"
            :pickerStyle="{ left: '-80px' }"
            v-model="marker.dash.color"
          />
        </div>

        <div class="labeled-input-container">
          <input
            type="number"
            class="form-control text-center"
            v-model="dashWidth"
            style="width:50%;"
          />
          <!-- <div style="padding:5px;margin-left:5px;text-align:center;"> -->
          <div class="form-control text-center" style="width:50%">
            <input
              type="checkbox"
              v-model="marker.dash.shadow"
              style="margin-top:8px;"
            />
          </div>
          <div class="small-label-input">
            {{ $t("width") }}
          </div>
          <div class="small-label-input" style="left:50%;margin-left:2px">
            {{ $t("Shadow") }}
          </div>
        </div>
      </div>
    </div>
    <!-- DASH END -->

    <div style="position:relative">
      <div style="position:absolute;right:-52px;">
        <IconLibrary
          ref="iconLibrary"
          v-model="marker.icon.class"
          :panelOpen="showIconLibrary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconLibrary from "@/components/editor/icon-library.vue";
import ColorPicker from "@/components/editor/color-picker";
export default {
  name: "ProgressBarMarker",
  props: {
    marker: {
      type: Object,
      required: true,
      default: () => null
    },
    control: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data() {
    return {
      showIconLibrary: false
    };
  },
  components: {
    IconLibrary,
    ColorPicker
  },
  computed: {
    rotation() {
      return (this.control && this.control.synopticComponent.rotation) || 0;
    },
    iconOffsetY: {
      set(value) {
        this.$set(this.marker.icon, "offsetY", value + "px");
      },
      get() {
        return (this.marker.icon.offsetY || "").replace(/px/, "");
      }
    },
    iconOffsetX: {
      set(value) {
        this.$set(this.marker.icon, "offsetX", value + "px");
      },
      get() {
        return (this.marker.icon.offsetX || "").replace(/px/, "");
      }
    },
    valueOffsetY: {
      set(value) {
        this.$set(this.marker.value, "offsetY", value + "px");
      },
      get() {
        return (this.marker.value.offsetY || "").replace(/px/, "");
      }
    },
    valueOffsetX: {
      set(value) {
        this.$set(this.marker.value, "offsetX", value + "px");
      },
      get() {
        return (this.marker.value.offsetX || "").replace(/px/, "");
      }
    },
    dashWidth: {
      set(value) {
        this.$set(this.marker.dash, "width", value + "px");
      },
      get() {
        return (this.marker.dash.width || "").replace(/px/, "");
      }
    }
  },
  watch: {
    rotation: {
      handler(n) {
        if (n) {
          if (this.valueOffsetX == "0") this.valueOffsetX = "-16";
          if (this.valueOffsetY == "5") this.valueOffsetY = "12";
        } else {
          if (this.valueOffsetX == "-16") this.valueOffsetX = "0";
          if (this.valueOffsetY == "12") this.valueOffsetY = "5";
        }
      },
      immediate: true
    }
  },
  methods: {
    rotateIcon() {
      this.marker.icon.rotation = (this.marker.icon.rotation || 360) - 45;
    }
  },
  mounted() {
    this.$refs.iconLibrary.$on("update:panelOpen", (value) => {
      this.showIconLibrary = value;
    });
  }
};
</script>

<style scoped>
.me {
  padding: 5px 10px 10px 10px;
  background-color: whitesmoke;
}

.me > .form-group {
  margin: 0;
}

.small-label-input {
  position: absolute;
  left: 5px;
  top: 0px;
  z-index: 3;
  font-size: 80%;
}

.labeled-input-container {
  position: relative;
}
.labeled-input-container > input {
  padding: 8px 0 0 0 !important;
}

.addon-btn {
  padding: 0;
  width: 32px;
}
.simple-label {
  font-weight: 600;
  color: #666;
  margin-left: -5px;
}
</style>
